const introSlider = () => {
  const introSliderElem = document.querySelector('.intro__slider');

  if (!introSliderElem) {
    return;
  }

  const modalGallerySlider = document.querySelector('.modal-gallery-wrap');
  const images = document.querySelectorAll('.intro__slide .high-res img');
  const breakpointSm = window.matchMedia('(max-width: 767px)');
  const sliderIntroSettings = {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    watchSlidesVisibility: true,

    // navigation: {
    //   nextEl: '.slider-controls-btn--next',
    //   prevEl: '.slider-controls-btn--prev',
    //   disabledClass: 'slider-controls-btn--disabled',
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    },

    on: {
      init: function () {
        if (modalGallerySlider) {
          modalGallerySlider.innerHTML = '';
          images.forEach((image) => {
            const slide = document.createElement('li');
            slide.className = 'modal-gallery__slide';
            slide.innerHTML = `<div class="modal-gallery__slide-img">
              <img src=${image.src} srcset=${image.srcset} width="508" height="337" alt="slide">
            </div>`;
            modalGallerySlider.appendChild(slide);
          });
        }
      },
    },

    slideClass: 'intro__slide',
    slideActiveClass: 'intro__slide--active',
    slideNextClass: 'intro__slide--next',
    slidePrevClass: 'intro__slide--prev',
    slideVisibleClass: 'intro__slide--visible',
    wrapperClass: 'intro__slider-wrap',
  };

  window.sliderIntro = new Swiper(introSliderElem, sliderIntroSettings);

  const breakpointChecker = () => {
    window.sliderIntro.destroy();
    window.sliderIntro = new Swiper(introSliderElem, sliderIntroSettings);
  };
  breakpointSm.addListener(breakpointChecker);
  breakpointChecker();
};


export {introSlider};
