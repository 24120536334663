window.initCursor = () => {
  let boxElems = document.querySelectorAll('.js-cursor-box');

  if (!boxElems.length) {
    return;
  }

  boxElems.forEach((boxElem) => {
    if (!boxElem.classList.contains('is-initialized') && !boxElem.classList.contains('js-no-cursor')) {
      boxElem.classList.add('is-initialized');
      boxElem.addEventListener('mousemove', onMouseMove.bind(null, boxElem), false);
      boxElem.addEventListener('mouseleave', disablePointer.bind(null, boxElem), false);
    }
  });

  function onMouseMove(box, event) {
    document.body.classList.add('no-cursor');
    let pointerElem = box.querySelector('.js-cursor');
    let mouseX = event.pageX;
    let mouseY = event.pageY;
    let crd = box.getBoundingClientRect();
    let activePointer = crd.left <= mouseX && mouseX <= crd.right && (crd.top + window.pageYOffset) <= mouseY && mouseY <= (crd.bottom + window.pageYOffset);

    requestAnimationFrame(function movePointer() {
      if (activePointer) {
        pointerElem.classList.remove('hidden');
        pointerElem.style.left = Math.floor(mouseX - crd.left) + 'px';
        pointerElem.style.top = Math.floor(mouseY - window.pageYOffset - crd.top) + 'px';
        if (event.target.closest('.js-fullscreen')) {
          pointerElem.classList.add('fullscreen');
        } else {
          pointerElem.classList.remove('fullscreen');
        }
      } else {
        pointerElem.classList.add('hidden');
        pointerElem.classList.remove('fullscreen');
      }
    });
  }

  function disablePointer(box) {
    document.body.classList.remove('no-cursor');
    let pointerElem = box.querySelector('.js-cursor');
    requestAnimationFrame(function hidePointer() {
      pointerElem.classList.add('hidden');
    });
  }
};
