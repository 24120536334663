const media767 = window.matchMedia('(max-width: 767px)');
const radioSlider = document.querySelector('.radio-slider__input--map');

const breakpointChecker = () => {
  if (media767.matches) {
    updatePaginationCount();
  } else {
    updatePaginationCount();
  }
};

const showProjectItems = () => {
  const projectItems = document.querySelectorAll('.projects__item');
  let itemsNumber = 4;
  itemsNumber = itemsNumber === 'all' ? projectItems.length : itemsNumber;
  let hiddenCount = 0;

  projectItems.forEach((item) => {
    if (item.style.display === 'flex') {
      itemsNumber += 1;
    }
  });

  projectItems.forEach((item, i) => {
    if (i + 1 <= itemsNumber) {
      item.style.display = 'flex';
    } else {
      item.style.display = 'none';
      hiddenCount = hiddenCount + 1;
    }
  });

  updatePaginationCount();
};

window.showProjectItems = showProjectItems;

const hideProjectItems = (count) => {
  const projectItems = document.querySelectorAll('.projects__item');
  let itemsNumber = count === undefined ? 4 : count;
  let hiddenCount = 0;

  projectItems.forEach((item, i) => {
    if (i + 1 <= itemsNumber) {
      item.style.display = 'flex';
    } else {
      item.style.display = 'none';
      hiddenCount = hiddenCount + 1;
    }
  });

  updatePaginationCount();
};

const updatePaginationCount = () => {
  const projectPaginationWrp = document.querySelector('.projects__all');
  const projectPagination = (projectPaginationWrp) ? projectPaginationWrp.querySelector('.projects__all-btn') : null;
  const paginationCount = (projectPagination) ? projectPagination.querySelectorAll('.projects__all-btn__count') : null;
  const paginationText = (projectPaginationWrp) ? projectPaginationWrp.querySelectorAll('.projects__all-btn__text') : null;
  const projectList = document.querySelector('.projects__list');

  const projectItems = document.querySelectorAll('.projects__item');
  let counter = 0;
  let counterVisible = 0;

  projectPagination.removeEventListener('click', showProjectItems);
  projectPagination.addEventListener('click', showProjectItems);

  projectItems.forEach((item) => {
    if (item.style.display === 'none') {
      counter += 1;
    } else {
      counterVisible += 1;
    }
  });

  const currentPage = counterVisible <= 4 ? 1 : Math.ceil(counterVisible / 4);
  projectList.dataset.currentPage = currentPage;
  projectList.dataset.nextPage = counter === 0 ? 0 : currentPage + 1;

  paginationCount.forEach((pagination) => {
    pagination.innerHTML = counter > 4 ? 4 : counter;
  });

  if (counter === 0) {
    projectPagination.classList.add('hidden');
  } else {
    projectPagination.classList.remove('hidden');
  }

  if (counter === 1) {
    paginationText.forEach((text) => {
      text.innerHTML = text.dataset.textOne;
    });
  } else {
    paginationText.forEach((text) => {
      text.innerHTML = text.dataset.textMore;
    });
  }

  if (counterVisible % 2 !== 0) {
    projectPaginationWrp.style.display = 'none';
  } else {
    projectPaginationWrp.style.display = 'block';
  }

  updateMobileStatus(projectItems.length, counterVisible, projectPaginationWrp);

  if (radioSlider && radioSlider.checked) {
    projectItems.forEach((item) => {
      item.style.display = 'flex';
    });
  }
};

window.updatePaginationCount = updatePaginationCount;

const updateMobileStatus = (allProj, visibleProj, pagination) => {
  const statusLine = pagination.querySelector('.projects__close-line-right');
  const backLine = pagination.querySelector('.projects__close-line-left');

  if (!media767.matches) {
    statusLine.style.width = '100%';
    statusLine.style.display = 'block';
    backLine.style.display = 'block';
    return;
  }

  statusLine.style.width = `${visibleProj / allProj * 128}px`;
  if (visibleProj / allProj === 1) {
    statusLine.style.display = 'none';
    backLine.style.display = 'none';
  } else {
    statusLine.style.display = 'block';
    backLine.style.display = 'block';
  }
};

//скрыть маркеры
function emptyProjects() {
  window.mapPins.forEach((pins) => {
    pins.marker.setVisible(false);
  });

  document.querySelector('.projects').classList.add('empty');
}

window.emptyProjects = emptyProjects;

function showProjects() {
  window.mapPins.forEach((pins) => {
    pins.marker.setVisible(true);
  });

  document.querySelector('.projects').classList.remove('empty');

  if (window.projectSlider !== undefined) {
    window.projectSlider.update();
  }
}

window.showProjects = showProjects;

const initProjectPagination = () => {
  const projectPaginationWrp = document.querySelector('.projects__all');
  const projectPagination = (projectPaginationWrp) ? projectPaginationWrp.querySelector('.projects__all-btn') : null;

  if (!projectPagination) {
    return;
  }

  showProjectItems();
  media767.addListener(breakpointChecker);
};

export {initProjectPagination, showProjectItems, hideProjectItems};
