import {setupModal} from '../utils/modal';
import {modalGallerySlider} from './modal-gallery-slider';

const modals = document.querySelectorAll('.modal');
const modalFeedback = document.querySelector('.modal--feedback');
const modalFeedbackBtns = document.querySelectorAll('[data-modal="feedback"]');
const modalSuccess = document.querySelector('.modal--success');
const modalSuccessBtns = document.querySelectorAll('[data-modal="success"]');
let modalGalleryBtns = document.querySelectorAll('[data-modal="gallery"]');
const modalForm = document.querySelector('.modal--form');
const modalFormBtns = document.querySelectorAll('[data-modal="form"]');

const modalGalleryOpenCallback = () => {
  const gallerySliderWrapper = document.querySelector('.gallery__wrapper');

  if (window.gallerySliderTop) {
    modalGallerySlider(window.gallerySliderTop.realIndex);
    window.sliderModalGallery.on('slideNextTransitionStart', function () {
      window.gallerySliderTop.slideNext();
      window.gallerySliderMiddle.slideNext();
      window.gallerySliderBottom.slideNext();
    });
    window.sliderModalGallery.on('slidePrevTransitionStart', function () {
      window.gallerySliderTop.slidePrev();
      window.gallerySliderMiddle.slidePrev();
      window.gallerySliderBottom.slidePrev();
    });
  }

  if (gallerySliderWrapper?.classList.contains('single')) {
    modalGallerySlider(1);
  } else if (gallerySliderWrapper?.classList.contains('double')) {
    modalGallerySlider(2);
  }

  if (document.querySelector('.intro__slider--modal')) {
    modalGalleryBtns.forEach((btn) => {
      if (btn.classList.contains('js-modal-active')) {
        let index = Number(btn.dataset.slideIndex);
        modalGallerySlider(index);
      }
    });
  }
};

const modalGalleryCloseCallback = () => {
  if (window.sliderModalGallery) {
    setTimeout(() => {
      window.sliderModalGallery.destroy(true, true);
    }, 500);
  }
};

// аргументы setupModal(modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock)
// возможна инициализация только с первыми аргументом,
// если вам нужно открывать модалку в другом месте под какими-нибудь условиями
const initModals = () => {
  // фикс для редких случаев, когда модалка появляется при загрузке страницы
  // window.addEventListener('load', () => {
    if (modals.length) {
      modals.forEach((el) => {
        // setTimeout(() => {
          el.classList.remove('modal--preload');
        // }, 100);
      });
    }
  // });

  if (modalFeedback && modalFeedbackBtns.length) {
    setupModal(modalFeedback, false, modalFeedbackBtns, false, false);
  }
  if (modalSuccess && modalSuccessBtns.length) {
    setupModal(modalSuccess, false, modalSuccessBtns);
  }
  if (modalForm && modalFormBtns.length) {
    setupModal(modalForm, false, modalFormBtns);
  }
};

const setupModalGallery = () => {
  const modalGallery = document.querySelector('.modal--gallery');
  modalGalleryBtns = document.querySelectorAll('[data-modal="gallery"]');
  if (modalGallery && modalGalleryBtns.length) {
    setupModal(modalGallery, modalGalleryCloseCallback, modalGalleryBtns, modalGalleryOpenCallback);
  }
};

export {initModals, setupModalGallery};
