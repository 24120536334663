import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {initModals, modalGalleryBtns, setupModalGallery} from './modules/init-modals';
import {initHeaderNav} from './modules/header-nav';
import {initBurgerAction} from './modules/burger-action';


import './modules/projects-list';

import {introBgSize} from './modules/fix-intro-bg';
import {introSlider} from './modules/intro-slider';
import {teamSlider} from './modules/team-slider';
import {ourHistorySlider} from './modules/our-history-slider';
import {initLabelMoving} from './modules/form/init-label-moving';
import {initEquipRadio} from './modules/equipment';
import {gallerySlider} from './modules/gallery-slider';
import {cookieConsentChecker} from './modules/cookie-consent-checker';
import {unmuteVideo} from './modules/unmute-video';
import {initFixHeader} from './modules/init-fix-header';
import {initOverflowCheck} from './modules/nav-overflow';
import {initMap} from './modules/init-map';
import {initFormModal} from './modules/form/form-modal';
import {initMoveTo} from './modules/init-moveTo';
import {initAsideFilter} from './modules/aside-filter';
import {initAside} from './modules/fix-aside-block';
import {newsBorderCheck} from './modules/init-news-border';
import {initLocalstorage} from './modules/init-localstorage';
import {initProjectPagination} from './modules/projects-pagination';

import './modules/cursor';

// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

initModals();
initHeaderNav();
initFixHeader();
initBurgerAction();
initLocalstorage();
teamSlider();
ourHistorySlider();
initLabelMoving();
initEquipRadio();
cookieConsentChecker();
unmuteVideo();
initOverflowCheck();
initMap();
initFormModal();
initMoveTo();
initAsideFilter();
initAside();
window.initCursor();
introBgSize();
introSlider();
initProjectPagination();


window.addEventListener('DOMContentLoaded', () => {
  gallerySlider();
  setupModalGallery();
  newsBorderCheck();
});
