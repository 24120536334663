import MoveTo from '../vendor/move-to';

const initAsideFilter = () => {
  const categoryLinks = document.querySelectorAll('.aside-filter__category');

  if (!categoryLinks.length) {
    return;
  }

  let activeLink = document.querySelector('.aside-filter__category--active');

  const visible = function (target, link) {
    // Позиции элемента
    let targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
    };
    // Получаем позиции окна
    let windowPosition = {
      top: window.pageYOffset + (document.documentElement.clientHeight / 2),
      bottom: window.pageYOffset + document.documentElement.clientHeight,
    };

    if (targetPosition.top <= windowPosition.top && // Если позиция верхней части элемента меньше или равно позиции верхней части окна, то элемент виден
      targetPosition.bottom > windowPosition.top) { // Если позиция нижней части элемента больше позиции верхней части окна, то элемент виден
      // Если элемент полностью видно, то запускаем следующий код
      if (!link.classList.contains('aside-filter__category--active')) {
        link.classList.add('aside-filter__category--active');
        activeLink = link;
      }
    } else {
      // Если элемент не видно, то запускаем этот код
      if (link.classList.contains('aside-filter__category--active')) {
        link.classList.remove('aside-filter__category--active');
      }
    }
  };

  const windowScrollHandler = () => {
    categoryLinks.forEach((item) => {
      const selector = item.getAttribute('href');
      const block = document.querySelector(selector);
      if (block) {
        visible(block, item);
      }
    });
  };


  categoryLinks.forEach((link) => {
    const moveTo = new MoveTo();

    moveTo.registerTrigger(link);

    link.addEventListener('click', () => {
      if (activeLink) {
        activeLink.classList.remove('aside-filter__category--active');
      }
      link.classList.add('aside-filter__category--active');
      activeLink = link;
      window.removeEventListener('scroll', windowScrollHandler);
      setTimeout(() => {
        link.blur();
        window.addEventListener('scroll', windowScrollHandler);
      }, 800);
    });
  });

  window.addEventListener('scroll', windowScrollHandler);

};

export {initAsideFilter};
