import {enableSlider} from './projects-list';
import {clickAction} from '../vendor/select';
import MoveTo from '../vendor/move-to';

let selects = document.querySelectorAll('.custom-input--select');

const initMap = () => {
  const myMap = document.getElementById('map');
  const projects = myMap ? myMap.classList.contains('projects__map') : null;
  const header = document.querySelector('.header');
  const breakpointSM = window.matchMedia('(max-width: 767px)');
  const projectToggle = document.querySelectorAll('.radio-slider__input');
  const mapView = document.querySelector('.radio-slider__input--map');
  const projectMarkerToggle = document.querySelector('.radio-slider__marker');
  const projectsWrapper = document.querySelector('.projects__list');
  let iconSrc = breakpointSM.matches ? header.dataset.pinsm : header.dataset.pin;
  let iconActiveSrc = breakpointSM.matches ? header.dataset.pinactivesm : header.dataset.pinactive;

  let mapCenter;

  let projectPins = [];
  let zoom;
  window.projectPins = projectPins;

  if (!projects) {
    mapCenter = {
      lat: 59.85249971562643,
      lng: 30.301564229693604,
    };

    zoom = breakpointSM.matches ? 11 : 9;
  } else {
    mapCenter = {
      lat: 43.916325,
      lng: 97.901060,
    };

    zoom = breakpointSM.matches ? 2 : 3;
  }

  let uluru = {
    lat: 59.85249971562643, lng: 30.301564229693604,
  };



  if (myMap) {
    let map = new google.maps.Map(document.getElementById('map'), {
      zoom: zoom,
      center: mapCenter,
      disableDefaultUI: true,
      zoomControl: true,
      styles: [
        {elementType: 'geometry', stylers: [{color: '#f5f5f5'}]},
        {elementType: 'labels.icon', stylers: [{visibility: 'off'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#616161'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#f5f5f5'}]},
        {featureType: 'administrative.land_parcel', elementType: 'labels.text.fill', stylers: [{color: '#bdbdbd'}]},
        {featureType: 'poi', elementType: 'geometry', stylers: [{color: '#eeeeee'}]},
        {featureType: 'poi', elementType: 'labels.text.fill', stylers: [{color: '#757575'}]},
        {featureType: 'poi.park', elementType: 'geometry', stylers: [{color: '#e5e5e5'}]},
        {featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{color: '#9e9e9e'}]},
        {featureType: 'road', elementType: 'geometry', stylers: [{color: '#ffffff'}]},
        {featureType: 'road.arterial', elementType: 'labels.text.fill', stylers: [{color: '#757575'}]},
        {featureType: 'road.highway', elementType: 'geometry', stylers: [{color: '#dadada'}]},
        {featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{color: '#616161'}]},
        {featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{color: '#9e9e9e'}]},
        {featureType: 'transit.line', elementType: 'geometry', stylers: [{color: '#e5e5e5'}]},
        {featureType: 'transit.station', elementType: 'geometry', stylers: [{color: '#eeeeee'}]},
        {featureType: 'water', elementType: 'geometry', stylers: [{color: '#c9c9c9'}]},
        {featureType: 'water', elementType: 'labels.text.fill', stylers: [{color: '#5980c1'}]}
      ],
    });

    window.map = map;

    if (projects) {
      generatePins();

      projectPins.forEach((pin) => {
        pin.marker = new google.maps.Marker({
          position: pin.position,
          map,
          icon: pin.icon,
          iconActive: pin.iconActive,
          location: pin.location,
          service: pin.service,
          selected: pin.selected,
        });

        google.maps.event.addListener(pin.marker, 'click', () => {
          if (pin.marker.selected) {
            pin.marker.setIcon(`${iconSrc}`);

            projectPins.forEach((pins) => {
              pins.marker.selected = false;
              pins.marker.setZIndex(1);
            });

          } else {
            projectPins.forEach((pins) => {
              pins.marker.selected = false;
              pins.marker.setZIndex(1);
            });

            pin.marker.selected = true;
            selectMarker(projectPins, pin.marker, iconSrc, iconActiveSrc);
            // selectToggle(pin.marker.location);

            const projectItems = document.querySelectorAll('.projects__item');
            projectItems.forEach((project, index) => {
              if (project.dataset.value === pin.location) {
                project.classList.add('selected');
                pin.marker.setZIndex(10);

                if (window.projectSlider !== undefined) {
                  window.projectSlider.slideTo(index);
                }
              } else {
                project.classList.remove('selected');
              }
            });

            projectsWrapper.scroll(0, 0);
          }
        });
      });

      window.mapPins = projectPins;

/* eslint-disable */
      initCardHover();
      projectsViewToggle();
      dragMap();

      projectToggle.forEach((view) => {
        if (view) {
          view.addEventListener('change', () => {
            if (view.dataset.projectsview === 'map') {
              const localProjectItems = document.querySelectorAll('.projects__item');

              if (localProjectItems.length) {
                //mapPanToCard(localProjectItems[0]);
                localProjectItems.forEach((item) => item.style.display = 'flex');
              }
            }
          });
        }
      });

      function projectsViewToggle () {
        if (!projectMarkerToggle) {
          return;
        }

        projectMarkerToggle.addEventListener('click', () => {
          projectToggle.forEach((view) => {
            if (view.dataset.projectsview === 'map' && view.checked) {
              const localProjectItems = document.querySelectorAll('.projects__item');

              if (localProjectItems.length) {
                //mapPanToCard(localProjectItems[0]);

              }
            }
          });
        });
      }

      window.addEventListener('load', () => {
        projectToggle
        setTimeout(() => {
          projectToggle.forEach((toggle) => {
            if (toggle.checked && toggle.dataset.projectsview === 'map') {
              const localProjectItems = document.querySelectorAll('.projects__item');

              // if (localProjectItems.length) {
              //   mapPanToCard(localProjectItems[0]);
              //   console.log('tyt')

              // }
            }
          });
        }, 50);
      });

      function dragMap () {
        map.addListener('drag', () => {
          projectPins.forEach((pin) => {
              pin.marker.selected = false;
              pin.marker.setIcon(`${iconSrc}`);
          });
        });
      };

      function mapPanToCard (card) {
        projectPins.forEach((pin) => {
          if (pin.marker.location === card.dataset.value && pin.marker.selected === false) {
            //map.panTo(new google.maps.LatLng(pin.marker.position.lat(), pin.marker.position.lng()));
          }
        });
      }

      function initCardHover () {
        const cards = document.querySelectorAll('.projects__item');
        cards.forEach((card) => {
          card.addEventListener('mouseenter', () => {
            projectPins.forEach((pin) => {
              // if (pin.marker.location === card.dataset.value && pin.marker.selected === false && mapView.checked) {
              if (pin.marker.location === card.dataset.value && mapView.checked) {
                pin.marker.selected = false;
                map.zoom = 3;
                pin.marker.setZIndex(10)
                pin.marker.setIcon(`${iconActiveSrc}`);
                //map.panTo(new google.maps.LatLng(pin.marker.position.lat(), pin.marker.position.lng()));
              }
            });
          });

          card.addEventListener('mouseleave', () => {
            projectPins.forEach((pin) => {
              if (pin.marker.location === card.dataset.value && pin.marker.selected === false) {
                pin.marker.setIcon(`${iconSrc}`);
                pin.marker.setZIndex(1);
              }
            });
          });
        });
      }

      window.initCardHover = initCardHover;

      function updatePins () {
        const header = document.querySelector('.header');
        let itemValue = '';
        const selectGeographyy = document.querySelector('[data-select="geography"]');
        const selectItemss = selectGeographyy.querySelectorAll('.custom-input__item');

        selectItemss.forEach((item) => {
          if (item.dataset.selected === "true") {
            itemValue = item.dataset.value;
          }
        });

        window.mapPins.forEach((pin) => {
          if (pin.marker.location === itemValue) {
            pin.marker.setIcon(`${iconActiveSrc}`);
            pin.marker.selected = true;
          } else {
            pin.marker.setIcon(`${iconSrc}`);

          }
        });
      };

      window.updatePins = updatePins();

      // initProjectFilter();
    } else {
      new google.maps.Marker({
        position: uluru,
        map,
        icon: header.dataset.pinactive ? header.dataset.pinactive : `${iconActiveSrc}`,
      });
    }


    function generatePins () {
      projectPins = [];
      const projectList = document.querySelectorAll('.projects__item');

      projectList.forEach((item) => {
        let duplicate = false;
        projectPins.forEach((pin) => {
          if (Number(item.dataset.lng) === pin.position.lng && Number(item.dataset.lat) === pin.position.lat) {
            duplicate = true;
          }
        });

        if (duplicate === false) {
          projectPins.push({
            position: {lat: Number(item.dataset.lat),
                        lng: Number(item.dataset.lng)},
            icon: `${iconSrc}`,
            iconActive: `${iconActiveSrc}`,
            location: item.dataset.value,
            selected: false,
          });
        }
      });
    }

    window.generatePins = generatePins;

    function selectToggle (dataGeography) {
      selects.forEach((select) => {
        select.querySelectorAll('.custom-input__item').forEach((item, index) => {
          if (item.dataset.value === dataGeography) {
            clickAction(item, index, item.textContent);
          }
        })
      })
    }

    function selectMarker (projectPins, markerClicked, iconSrc, iconActiveSrc) {
      projectPins.forEach((n) => {
        n.marker.setIcon(n.marker !== markerClicked ? `${iconSrc}` : `${iconActiveSrc}`);
      });
    }

    function updateSlider () {
      if (!window.projectSlider) {
        return;
      }

      window.projectSlider.update();

      let slidesCounterAll = document.querySelector('.slides-counter__all-slides');
      let slidesCounterActive = document.querySelector('.slides-counter__active-slide');
      let items = document.querySelectorAll('.projects__item');

      slidesCounterAll.innerHTML = items.length;

      slidesCounterActive.innerHTML = window.projectSlider.realIndex + 1;
    }

    window.addEventListener('resize', () => {
      iconSrc = breakpointSM.matches ? header.dataset.pinsm : header.dataset.pin;
      iconActiveSrc = breakpointSM.matches ? header.dataset.pinactivesm : header.dataset.pinactive;
    })
  }
};

const updateVisiblePins = () => {
  const projects = document.querySelectorAll('.projects__item');

  window.mapPins.forEach((pin) => {
    let visible = false;
    projects.forEach((project) => {
      if (pin.position.lat === Number(project.dataset.lat) && pin.position.lng === Number(project.dataset.lng)) {
        pin.marker.setVisible(true);
        visible = true;
      } else if (!visible) {
        pin.marker.setVisible(false);
      }
    });
  });
};

window.updateVisiblePins = updateVisiblePins;

export {initMap};
