import {Cookie} from '../vendor/cookie';

const cookieNode = document.querySelector('.js-cookies');

const cookieConsentChecker = () => {
  if (!cookieNode) {
    return;
  }
  const duration = cookieNode.dataset.duration;
  cookieNode.style.transition = `transform ${duration}ms, visibility ${duration}ms, height ${duration}ms, min-height ${duration}ms`;

  const consentCookie = new Cookie('has_cookie_consent');

  if (!consentCookie.get('has_cookie_consent')) {
    const cookieCloseBtns = cookieNode.querySelectorAll('.js-cookies-close-btn');

    cookieNode.classList.add('cookies-note--active');

    cookieCloseBtns.forEach((btn) => {
      btn.addEventListener('click', function (evt) {
        evt.preventDefault();
        const cookieDuration = cookieNode.dataset.duration;

        cookieNode.classList.remove('cookies-note--active');

        if (cookieDuration) {
          consentCookie.set('yes', cookieDuration);
        } else {
          consentCookie.set('yes', 30);
        }
      });
    });
  }
};

export {cookieConsentChecker};
