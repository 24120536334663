
const header = document.querySelector('.header');
const navContents = document.querySelectorAll('.nav-content');
const navItems = document.querySelectorAll('.js-nav-item');
const breakpointMd = window.matchMedia('(min-width:1024px)');
const pointMd = window.matchMedia('(1023px)');
const logoText = document.querySelectorAll('.logo-icon-text');
const touchDevice = window.matchMedia('(pointer: coarse)').matches;
let menuHoverTimeout;

const initHeaderNav = () => {
  navContentPos();
  navFocus();


  if (touchDevice) {
    navContents.forEach((content) => {
      content.classList.add('nav-content--touch');
    });

    navItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        if (breakpointMd.matches) {
          if (!header.classList.contains('header--light')) {
            header.classList.add('header--light');
          }
          item.classList.toggle('nav-accordion-toggle--active');
          navContents[index].classList.toggle('nav-content--active');
          header.classList.toggle('header--no-line');
        }
      });
    });
  }

  window.addEventListener('resize', () => {
    navContentPos();

    if (pointMd) {
      removeNavActiveState();
    }
  });

  window.addEventListener('orientationchange', () => {
    navContentPos();
    removeNavActiveState();
  });

  header.addEventListener('mouseenter', () => {
    if (breakpointMd.matches) {
      headerActiveState();
    }

    if (touchDevice) {
      navContents.forEach((content) => {
        content.classList.add('nav-content--touch');
      });
    } else {
      navContents.forEach((content) => {
        content.classList.remove('nav-content--touch');
      });
    }
  });

  header.addEventListener('mouseleave', () => {
    if (!header.classList.contains('header--fixed') && !header.classList.contains('header--no-line')) {
      headerRemoveActiveState();
    }
    //removeNavActiveState();
  });

  navItems.forEach((item, index) => {
    item.addEventListener('mouseover', () => {
      if (breakpointMd.matches && !touchDevice) {
        clearTimeout(menuHoverTimeout);
        removeNavActiveState();
        item.classList.toggle('nav-accordion-toggle--active');
        navContents[index].classList.toggle('nav-content--active');
        headerActiveState();
        header.classList.add('header--no-line');
      }
    });

    item.addEventListener('mouseleave', (event) => {
      menuHoverTimeout = setTimeout(() => {
        if (!header.contains(event.relatedTarget) && !header.classList.contains('header--fixed')) {
          headerRemoveActiveState();
        }
        removeNavActiveState();
      }, 600);
    });
  });
};

const headerActiveState = () => {
  setTimeout(() => {
    header.classList.add('header--light');
    logoText.forEach((text) => text.classList.add('fill-black'));
  }, 50);
};

const headerRemoveActiveState = () => {
  header.classList.remove('header--light');
  logoText.forEach((text) => text.classList.remove('fill-black'));
};


const removeNavActiveState = () => {
  header.classList.remove('header--no-line');
  navContents.forEach((content) => {
    content.classList.remove('nav-content--active');
  });
  navItems.forEach((item) => {
    item.classList.remove('nav-accordion-toggle--active');
  });
};


const navContentPos = () => {
  const accordionLineLeft = document.querySelectorAll('.accordion-nav-marker-left');
  const accordionLineRight = document.querySelectorAll('.accordion-nav-marker-right');

  accordionLineLeft.forEach((line, index) => {
    line.style.width = 0 + 'px';
    line.style.left = 0 + 'px';
    line.style.width = line.getBoundingClientRect().left + (10 * (index + 1)) + 'px';
    line.style.left = line.getBoundingClientRect().left * -1 - (10 * (index + 1)) + 'px';
  });

  accordionLineRight.forEach((line) => {
    line.style.width = document.documentElement.clientWidth - line.getBoundingClientRect().left + 'px';
    line.style.left = 26 + 'px';
  });

  navContents.forEach((item) => {
    setTimeout(() => {
      item.style.left = 0 + 'px';
      item.style.left = (item.getBoundingClientRect().left - header.getBoundingClientRect().left) * -1 + 'px';
    }, 500);
  });
};

const navFocus = () => {
  if (touchDevice) {
    return;
  }

  const navLinks = document.querySelectorAll('.main-nav__link');
  const accordionLinks = document.querySelectorAll('.nav-content__link');
  const headerLogo = document.querySelector('.header__logo');
  const headerTel = document.querySelector('.main-nav__tel');
  const headerLanguage = document.querySelectorAll('[name="language"]');
  let isOnClick = false;

  navLinks.forEach((link) => initNavFocus(link));
  headerLanguage.forEach((item) => initNavFocus(item));
  if (headerLogo) {
    initNavFocus(headerLogo);
  }
  initNavFocus(headerTel);

  function initNavFocus(item) {
    item.addEventListener('mousedown', () => (isOnClick = false));
    item.addEventListener('mouseup', () => {
      setTimeout(() => {
        isOnClick = true;
      }, 1);
    });


    item.addEventListener('focus', () => {
      headerActiveState();
    });

    item.addEventListener('blur', () => {
      if (isOnClick) {
        headerRemoveActiveState();
      }
    });
  }

  accordionLinks.forEach((link) => {
    link.addEventListener('mousedown', () => (isOnClick = false));
    link.addEventListener('mouseup', () => {
      setTimeout(() => {
        isOnClick = true;
      }, 1);
    });

    link.addEventListener('focus', () => {
      headerActiveState();
      link.closest('.nav-content').classList.add('nav-content--active');
      link.closest('.js-nav-item').classList.add('nav-accordion-toggle--active');
      header.classList.add('header--no-line');
    });

    link.addEventListener('blur', () => {
      removeNavActiveState();
      link.closest('.nav-content').classList.remove('nav-content--active');
    });
  });
};

export {initHeaderNav};
export {navContentPos};
export {removeNavActiveState};
