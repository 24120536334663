let eventFlag = false;

const modalGallerySlider = (initialSlide) => {

  const modalGallerySliderElem = document.querySelector('.modal-gallery');

  if (!modalGallerySliderElem) {
    return;
  }

  const modalPrevBtn = document.querySelector('.modal-gallery-btn-prev');
  const modalNextBtn = document.querySelector('.modal-gallery-btn-next');
  const breakpointSm = window.matchMedia('(max-width: 767px)');
  const sliderGallerySettings = {
    initialSlide,
    // loop: true,
    // watchSlidesVisibility: true,

    // controller: {
    //   control: window.mySwiperTop,
    // },

    navigation: {
      nextEl: modalNextBtn,
      prevEl: modalPrevBtn,
      disabledClass: 'slider-controls-btn--disabled',
    },

    slideClass: 'modal-gallery__slide',
    slideActiveClass: 'modal-gallery__slide--active',
    slideNextClass: 'modal-gallery__slide--next',
    slidePrevClass: 'modal-gallery__slide--prev',
    slideVisibleClass: 'modal-gallery__slide--visible',
    wrapperClass: 'modal-gallery-wrap',
    preloadImages: false,
  };

  window.sliderModalGallery = new Swiper(modalGallerySliderElem, sliderGallerySettings);

  if (eventFlag === false) {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'ArrowLeft' || e.code === 'KeyA') {
        window.sliderModalGallery.slidePrev();
      } else if (e.code === 'ArrowRight' || e.code === 'KeyD') {
        window.sliderModalGallery.slideNext();
      }
    });

    eventFlag = true;
  }

  // const breakpointChecker = () => {
  //   window.sliderModalGallery.destroy();
  //   window.sliderModalGallery = new Swiper(modalGallerySliderElem, sliderGallerySettings);
  // };
  // breakpointSm.addListener(breakpointChecker);
  // breakpointChecker();
};


export {modalGallerySlider};
