import {disableScrolling, enableScrolling} from '../utils/scroll-lock';

const burgerBtn = document.querySelector('.main-nav__toggle');
const navBlock = document.querySelector('.main-nav__wrapper');
const breakpointLg = window.matchMedia('(min-width:1024px)');
const header = document.querySelector('.header');

const openMenu = () => {
  disableScrolling();
  navBlock.scroll(0, 0);
  burgerBtn.ariaPressed = 'true';
  navBlock.classList.add('main-nav--open');
  burgerBtn.classList.add('main-nav__toggle--active');

  setTimeout(() => {
    header.classList.remove('is-anim');
  }, 1);
};

const closeMenu = () => {
  enableScrolling();

  burgerBtn.ariaPressed = 'false';
  navBlock.classList.remove('main-nav--open');
  burgerBtn.classList.remove('main-nav__toggle--active');

  setTimeout(() => {
    header.classList.remove('is-anim');
  }, 1);
};

const closeMenuOnResize = () => {
  closeMenu();
};

const initBurgerAction = () => {
  if (burgerBtn) {
    breakpointLg.addListener(closeMenuOnResize);
    burgerBtn.addEventListener('click', () => {
      if (burgerBtn.ariaPressed === 'true') {
        closeMenu();
      } else {
        openMenu();
      }
    });
  }
};

export {initBurgerAction};
