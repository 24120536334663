const initEquipRadio = () => {
  const equipRadio = document.querySelectorAll('[name="equipmentRadio"]');
  const equipTitle = document.querySelector('.intro__title--equipment');

  equipRadio.forEach((radio) => {
    radio.addEventListener('change', () => {
      equipTitle.innerHTML = radio.dataset.equipment;
      inputChecker(equipTitle, equipRadio);
    });
  });
};

const inputChecker = (title, radios) => {
  let check = false;

  radios.forEach((radio) => {
    if (radio.checked && check === true) {
      title.innerHTML = title.dataset.equipment;
    } else if (radio.checked) {
      title.innerHTML = radio.dataset.equipment;
      check = true;
    }
  });

  if (check === false) {
    title.innerHTML = title.dataset.equipment;
  }
};

export {initEquipRadio};
