import {showProjectItems, hideProjectItems} from './projects-pagination';

const projectsView = document.querySelectorAll('.radio-slider__input');
const projectsContainer = document.querySelector('.js-projects');
const radioToggle = document.querySelector('.radio-slider__marker');

const breakpointSM = window.matchMedia('(min-width:768px)');
const header = document.querySelector('.header');
let iconSrc = breakpointSM.matches ? header.dataset.pin : header.dataset.pinsm;
let iconActiveSrc = breakpointSM.matches ? header.dataset.pinactive : header.dataset.pinactivesm;

let projectItems = document.querySelectorAll('.projects__item');
let slidesCounterActive = document.querySelector('.slides-counter__active-slide');
let slidesCounterAll = document.querySelector('.slides-counter__all-slides');

if (radioToggle) {
  radioToggle.addEventListener('click', () => {
    for (let i = 0; i < projectsView.length; i++) {
      if (projectsView[i].checked === false) {
        projectsView[i].checked = true;
        if (projectsView[i].dataset.projectsview === 'map') {
          mapView();
        } else {
          listView();
        }

        return;
      }
    }
  });
}

window.addEventListener('load', () => {
  setTimeout(() => {
    projectsView.forEach((view) => {
      if (view.checked && view.dataset.projectsview === 'map') {
        mapView();
      } else {
        listView();
      }
    });
  }, 1);
});


projectsView.forEach((view) => {
  view.addEventListener('change', () => {
    if (view.dataset.projectsview === 'map') {
      mapView();
    } else {
      listView();
    }
  });
});

const listView = () => {
  projectsContainer.classList.add('container--projects-list');
  projectsContainer.classList.remove('container--projects-map');
  sliderChecker();
  hideProjectItems();
};

const mapView = () => {
  projectsContainer.classList.add('container--projects-map');
  projectsContainer.classList.remove('container--projects-list');
  sliderChecker();
  showProjectItems('all');

  const allProjectItems = document.querySelectorAll('.projects__item');
  allProjectItems.forEach((item) => {
    item.style.display = 'flex';
  });
};

const enableSlider = () => {
  window.projectSlider = new Swiper('.swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    spaceBetween: 0,
    noSwipingClass: 'projects__map',
  });

  slidesCounterAll.innerHTML = window.projectSlider.slides.length;
  updateActivePin();

  window.addEventListener('resize', () => {
    slidesCounterAll.innerHTML = projectItems.length;
  });

  window.projectSlider.on('update', () => {
    slidesCounterAll.innerHTML = window.projectSlider.slides.length;
  });

  window.projectSlider.on('slideChangeTransitionEnd', () => {
    slidesCounterActive.innerHTML = window.projectSlider.realIndex + 1;

    updateActivePin();
  });
};

const updateActivePin = () => {
  const slides = document.querySelectorAll('.projects__item');
  const activeSlide = slides[window.projectSlider.activeIndex];

  if (!window.mapPins) {
    return;
  }

  window.mapPins.forEach((pin) => {
    if (pin.position.lat === Number(activeSlide.dataset.lat) && pin.position.lng === Number(activeSlide.dataset.lng)) {
      pin.marker.selected = false;
      window.map.zoom = 1;
      pin.marker.setZIndex(10);
      pin.marker.setIcon(`${iconActiveSrc}`);
      //window.map.panTo(new google.maps.LatLng(pin.marker.position.lat(), pin.marker.position.lng()));
    } else {
      pin.marker.setIcon(`${iconSrc}`);
      pin.marker.setZIndex(1);
    }
  });
};

window.enableSlider = () => {
  let projectItems = document.querySelectorAll('.projects__item');
  let slidesCounterActive = document.querySelector('.slides-counter__active-slide');
  let slidesCounterAll = document.querySelector('.slides-counter__all-slides');

  window.projectSlider = new Swiper('.swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    spaceBetween: 0,
    noSwipingClass: 'projects__map',
  });

  slidesCounterAll.innerHTML = window.projectSlider.slides.length;
  slidesCounterActive.innerHTML = window.projectSlider.realIndex + 1;

  updateActivePin();

  window.addEventListener('resize', () => {
    slidesCounterAll.innerHTML = projectItems.length;
  });

  window.projectSlider.on('update', () => {
    slidesCounterAll.innerHTML = window.projectSlider.slides.length;
  });

  window.projectSlider.on('slideChangeTransitionEnd', () => {
    slidesCounterActive.innerHTML = window.projectSlider.realIndex + 1;

    updateActivePin();
  });
};


const sliderChecker = function () {
  if (projectsContainer) {
    if (breakpointSM.matches === true || projectsContainer.classList.contains('container--projects-list')) {
      if (window.projectSlider !== undefined) {
        window.projectSlider.destroy(true, true);
      }
    } else if (breakpointSM.matches === false && projectsContainer.classList.contains('container--projects-map')) {
      enableSlider();
    } else if (breakpointSM.matches === false && projectsContainer.classList.contains('container--projects-list-main')) {
      enableSlider();
    }
  }
};

breakpointSM.addListener(sliderChecker);
sliderChecker();

export {enableSlider};
