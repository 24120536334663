const newsBorderCheck = () => {
  const newsItems = document.querySelectorAll('.news__section');

  if (newsItems.length === undefined) {
    return;
  }

  newsItems.forEach((item) => item.classList.remove('no-border'));
  newsItems.forEach((item, index) => {
    if (item.style.display === 'none') {
      newsItems[index - 1].classList.add('no-border');
    }
  });
};

window.newsBorderCheck = newsBorderCheck;

export {newsBorderCheck};
