const ourHistorySlider = () => {
  const ourHistorySliderElem = document.querySelector('.our-history__slider');

  if (!ourHistorySliderElem) {
    return;
  }

  const cursor = ourHistorySliderElem.closest('.js-cursor-box');
  const breakpointSm = window.matchMedia('(max-width: 767px)');
  const sliderOurHistorySettings = {
    slidesPerView: 1,
    watchSlidesVisibility: true,
    simulateTouch: true,

    navigation: {
      nextEl: '.slider-controls-btn--next',
      prevEl: '.slider-controls-btn--prev',
      disabledClass: 'slider-controls-btn--disabled',
    },

    breakpoints: {
      768: {
        slidesPerView: 'auto',
        simulateTouch: true,
      },
      1440: {
        simulateTouch: ourHistorySliderElem.querySelectorAll('.our-history__slide').length >= 4,
        slidesPerView: 'auto',
      },
    },

    slideClass: 'our-history__slide',
    slideActiveClass: 'our-history__slide--active',
    slideNextClass: 'our-history__slide--next',
    slidePrevClass: 'our-history__slide--prev',
    slideVisibleClass: 'our-history__slide--visible',
    wrapperClass: 'our-history__slider-wrap',
  };

  window.sliderOurHistory = new Swiper(ourHistorySliderElem, sliderOurHistorySettings);

  if (window.sliderOurHistory.slides.length < 4) {
    if (cursor) {
      cursor.classList.add('js-no-cursor');
    }
  }

  if (window.sliderOurHistory.slides.length < 2) {
    window.sliderOurHistory.navigation.destroy();
    window.sliderOurHistory.navigation.$nextEl[0].style.display = 'none';
    window.sliderOurHistory.navigation.$prevEl[0].style.display = 'none';
  }

  const breakpointChecker = () => {
    window.sliderOurHistory.destroy();
    window.sliderOurHistory = new Swiper(ourHistorySliderElem, sliderOurHistorySettings);
  };
  breakpointSm.addListener(breakpointChecker);
  breakpointChecker();
};


export {ourHistorySlider};
