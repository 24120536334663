import {navContentPos, removeNavActiveState} from './header-nav';
import {initOverflowCheck} from './nav-overflow';

const header = document.querySelector('.header');
let startPoint = window.innerHeight;
let scrollPosition = 0;
let lastScrollTop = 0;
const burgerBtn = document.querySelector('.main-nav__toggle');

const toggleHeaderState = () => {
  window.addEventListener('resize', () => {
    startPoint = window.innerHeight;
  });

  window.addEventListener('scroll', () => {
    setTimeout(() => {
      scrollPosition = window.pageYOffset;


      if (scrollPosition > startPoint) {
        header.classList.add('header--fixed');
        header.classList.add('header--light');
        header.classList.add('is-anim');
        header.classList.add('active');

        document.querySelectorAll('.logo-icon-text').forEach((text) => text.classList.add('fill-black'));
        navContentPos();
        initOverflowCheck();

      } else {
        if (!burgerBtn.classList.contains('main-nav__toggle--active')) {
          header.classList.remove('active');

          setTimeout(() => {
            header.classList.remove('is-anim');
            header.classList.remove('header--fixed');
            header.classList.remove('header--light');
            document.querySelectorAll('.logo-icon-text').forEach((text) => text.classList.remove('fill-black'));
            navContentPos();
            removeNavActiveState();
            initOverflowCheck();
          }, 250);

          setTimeout(() => {
            initOverflowCheck();
          }, 300);
        }
      }
    }, 50);
  }, false);
};

const initFixHeader = () => {
  if (header) {
    toggleHeaderState();
  }
};

export {initFixHeader};
