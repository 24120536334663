import MoveTo from '../vendor/move-to';

const initMoveTo = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const moveTo = new MoveTo();

    const triggers = document.querySelectorAll('.js-trigger');

    if (!triggers) {
      return;
    }

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const target = document.querySelector(`${trigger.dataset.target}`);
        moveTo.move(target);
      });
    });
  });
};

export {initMoveTo};
