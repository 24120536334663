const teamSlider = () => {
  const teamSliderElem = document.querySelector('.team__slider');

  if (!teamSliderElem) {
    return;
  }

  const small = teamSliderElem.classList.contains('team__slider--small');
  const breakpointSm = window.matchMedia('(max-width: 767px)');
  const teamSlides = teamSliderElem.querySelectorAll('.team__slide');
  const paginationCurrent = teamSliderElem.querySelector('.slider-pagination__current');
  const paginationTotal = teamSliderElem.querySelector('.slider-pagination__total');
  const sliderTeamSettings = {
    slidesPerView: 1,
    spaceBetween: small ? 24 : 20,
    loop: true,
    loopAdditionalSlides: 3,
    // grabCursor: true,

    // navigation: {
    //   nextEl: '.slider-controls-btn--next',
    //   prevEl: '.slider-controls-btn--prev',
    //   disabledClass: 'slider-controls-btn--disabled',
    // },

    pagination: {
      el: '.slider-pagination__progressbar',
      type: 'progressbar',
    },

    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
    },

    on: {
      init: function () {
        if (paginationCurrent && paginationTotal) {
          paginationCurrent.innerText = this.realIndex + 1;
          paginationTotal.innerText = teamSlides.length;
        }
      },
      slideChange: function () {
        if (paginationCurrent) {
          paginationCurrent.innerText = this.realIndex + 1;
        }
      },
    },

    slideClass: 'team__slide',
    slideActiveClass: 'team__slide--active',
    slideNextClass: 'team__slide--next',
    slidePrevClass: 'team__slide--prev',
    wrapperClass: 'team__slider-wrap',
  };

  window.sliderTeam = new Swiper(teamSliderElem, sliderTeamSettings);

  const breakpointChecker = () => {
    window.sliderTeam.destroy();
    window.sliderTeam = new Swiper(teamSliderElem, sliderTeamSettings);
  };
  breakpointSm.addListener(breakpointChecker);
  breakpointChecker();
};


export {teamSlider};
