const padding = 40;
const aside = document.querySelector('.aside');
let content;

const getHeaderHeight = () => {
  return document.querySelector('.header').getBoundingClientRect().height;
};

const animateAside = () => {
  if (!aside) {
    return;
  }
  const breakpoint = aside.dataset.breakpoint ? window.matchMedia(`(min-width:${aside.dataset.breakpoint}px)`) : window.matchMedia('(min-width:768px)');

  if (breakpoint.matches) {
    const offset = window.pageYOffset + getHeaderHeight();
    const contentHeight = content.getBoundingClientRect().height;
    const topFix = aside.getBoundingClientRect().top + window.pageYOffset - padding;
    const heightDifference = aside.getBoundingClientRect().height - contentHeight;
    const top = aside.getBoundingClientRect().top;
    const limitPosition = heightDifference + top - getHeaderHeight() - padding;
    aside.style.minHeight = contentHeight + 'px';
    if (offset > topFix) {
      if (limitPosition > 0) {
        content.setAttribute('style', 'position: fixed; top: ' + (getHeaderHeight() + padding) + 'px; width: ' + aside.clientWidth + 'px');
      } else {
        content.setAttribute('style', 'position: absolute; top: ' + (heightDifference) + 'px');
      }
    } else {
      content.removeAttribute('style');
    }
  } else {
    content.removeAttribute('style');
    aside.removeAttribute('style');
  }
};

const initAside = () => {
  if (!aside) {
    return;
  }
  content = aside.querySelector('.aside__content');
  animateAside();
  window.addEventListener('scroll', animateAside);
  const callback = () => {
    animateAside();
  };
  const resizeObserver = new ResizeObserver(callback);
  resizeObserver.observe(aside);
};

export {initAside, animateAside};
