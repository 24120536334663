const introBg = document.querySelector('.intro__bg');
const intro = document.querySelector('.intro');
const introHeightCalc = () => intro.getBoundingClientRect().height + 80;


const introBgSize = () => {
  if (!intro || !introBg) {
    return;
  }

  window.addEventListener('resize', () => {
    introBg.style.height = introHeightCalc() + 'px';
  });

  introBg.style.height = introHeightCalc() + 'px';

  window.addEventListener('load', () => {
    introBg.style.height = introHeightCalc() + 'px';
  });
};

export {introBgSize};
