const toggleMap = document.querySelector('.radio-slider__input--map');
const toggleList = document.querySelector('.radio-slider__input--list');
const toggleMarker = document.querySelector('.radio-slider__marker');

function onToggleChange() {
  if (this.checked && this.dataset.projectsview === 'map') {
    localStorage.setItem('projectToggle', 1);
  } else if (localStorage.getItem('projectToggle') !== null) {
    localStorage.removeItem('projectToggle');
  }
};

const onMarkChange = (map) => {
  if (map) {
    localStorage.setItem('projectToggle', 1);
  } else {
    localStorage.removeItem('projectToggle');
  }
}

const initLocalstorage = () => {
  if (!toggleMap || !toggleList) {
    return;
  }

  if (localStorage.getItem('projectToggle') !== null) {
    toggleMap.checked = true;
  }

  toggleMap.addEventListener('change', onToggleChange);
  toggleList.addEventListener('change', onToggleChange);
  toggleMarker.addEventListener('click', () => {
    if (toggleMap.checked) {
      onMarkChange(true);
    } else {
      onMarkChange(false);
    }
  })
};

export {initLocalstorage};
