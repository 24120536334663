const formItems = [...document.querySelectorAll('.js-form-item')];

const initLabelMoving = () => {
  if (formItems.length) {
    formItems.forEach((el) => {
      el.addEventListener('input', ({target}) => {
        const parent = target.closest('label');
        if (parent) {
          const label = parent.querySelector('span');
          if (target.value.length && target.dataset.moved === 'true') {
            label.classList.add('moved');
          } else {
            label.classList.remove('moved');
          }
        }
      });
    });
  }
};

export {initLabelMoving};
