const gallerySlider = () => {
  const gallerySliderElems = document.querySelectorAll('.gallery__slider');

  if (!gallerySliderElems.length) {
    return;
  }

  // const breakpointSm = window.matchMedia('(max-width: 767px)');
  const modalGallerySlider = document.querySelector('.modal-gallery-wrap');
  const gallerySliderWrapper = document.querySelector('.gallery__wrapper');
  let images = document.querySelectorAll('.gallery__slider--top img');
  const textBlock = document.querySelector('.gallery__description');

  function initModalGallery() {
    modalGallerySlider.innerHTML = '';
    if (images.length === 2) {
      // images = document.querySelectorAll('.gallery__slider--middle img');
    } else if (images.length === 1) {
      // images = document.querySelectorAll('.gallery__slider--bottom img');
    }
    images.forEach((image) => {
      const slide = document.createElement('li');
      slide.className = 'modal-gallery__slide';
      slide.innerHTML = `<div class="modal-gallery__slide-img">
              <img src=${image.src} srcset=${image.srcset} width="508" height="337" alt="slide">
            </div>`;
      modalGallerySlider.appendChild(slide);
    });
  }

  if (images.length >= 1) {
    gallerySliderElems.forEach((slider, i) => {
      const sliderNextBtn = slider.querySelector('.slider-controls-btn--next');
      const sliderPrevBtn = slider.querySelector('.slider-controls-btn--prev');
      const sliderPagination = slider.querySelector('.slider-pagination');

      window['gallerySlider' + slider.dataset.sliderName] = new Swiper(slider, {
        effect: slider.dataset.effect,
        allowTouchMove: slider.classList.contains('gallery__slider--top'),
        watchSlidesVisibility: true,

        navigation: {
          nextEl: sliderNextBtn,
          prevEl: sliderPrevBtn,
          disabledClass: 'slider-controls-btn--disabled',
        },

        pagination: {
          el: sliderPagination,
          type: 'fraction',
        },

        on: slider.classList.contains('gallery__slider--top') ? {
          init: initModalGallery,
        } : false,

        slideClass: 'gallery__slide',
        slideActiveClass: 'gallery__slide--active',
        slideNextClass: 'gallery__slide--next',
        slidePrevClass: 'gallery__slide--prev',
        slideVisibleClass: 'gallery__slide--visible',
        wrapperClass: 'gallery__slider-wrap',
      });
      /*
      if (window['gallerySlider' + slider.dataset.sliderName].slides.length === 3) {
        const sliderNavigation = slider.querySelector('.slider-controls');

        slider.closest('.gallery__wrapper').classList.add('disabled');

        if (sliderNavigation) {
          sliderNavigation.style.display = 'none';
        }
      }
      */
    });

    window.gallerySliderTop.on('slideNextTransitionStart', function () {
      window.gallerySliderMiddle.slideNext();
      window.gallerySliderBottom.slideNext();
      if (textBlock) {
        textBlock.style.opacity = 0;
      }
    });

    window.gallerySliderTop.on('slidePrevTransitionStart', function () {
      window.gallerySliderMiddle.slidePrev();
      window.gallerySliderBottom.slidePrev();
    });

    window.gallerySliderTop.on('slideChangeTransitionStart', function () {
      if (textBlock) {
        textBlock.style.opacity = 0;
      }
    });

    window.gallerySliderTop.on('slideChangeTransitionEnd', function () {
      if (textBlock) {
        textBlock.textContent = this.slides[this.realIndex].dataset.text;
        textBlock.style.opacity = 1;
      }
      this.navigation.nextEl.blur();
      this.navigation.prevEl.blur();
    });
  } else {
    gallerySliderWrapper.classList.add('single');
    initModalGallery();
  }
  // const breakpointChecker = () => {
  //   window.sliderGallery.destroy();
  //   window.sliderGallery = new Swiper(gallerySliderElem, sliderGallerySettings);
  // };
  // breakpointSm.addListener(breakpointChecker);
  // breakpointChecker();
};


export {gallerySlider};
