let activeVideo = null;
let activeVideoBtn = null;

const unmuteVideo = () => {
  const btns = document.querySelectorAll('.unmute');
  btns.forEach((btn) => {
    if (!btn) {
      return;
    }
    const video = document.querySelector(`#${btn.dataset.video}`);
    if (btn.classList.contains('lazy')) {
      videoLazyLoad(video);
    } else {
      video.src = video.dataset.src;
    }
    btn.addEventListener('click', () => {
      if (video.dataset.autoplay === 'true') {
        toggleAudio(btn, video);
      } else {
        if (!video.classList.contains('playing')) {
          video.play();
        } else {
          video.pause();
        }
        toggleAudio(btn, video);
      }
    });
  });
};

const videoLazyLoad = (video) => {
  if (!video) {
    return;
  }
  window.addEventListener('load', () => {
    video.src = video.dataset.src;
  });
};

const toggleAudio = (btn, video) => {
  if (!video) {
    return;
  }
  if (video.classList.contains('playing')) {
    video.classList.remove('playing');
    activeVideo = null;
  } else {
    video.classList.add('playing');
    if (activeVideo) {
      activeVideo.classList.remove('playing');
      activeVideo.muted = true;
    }
    activeVideo = video;
  }
  if (btn.classList.contains('active')) {
    btn.classList.remove('active');
  } else {
    btn.classList.add('active');
    if (activeVideoBtn) {
      activeVideoBtn.classList.remove('active');
    }
    activeVideoBtn = btn;
  }
  video.muted = !video.muted;
};

export {unmuteVideo};

document.addEventListener('DOMContentLoaded', function(){
  if (document.getElementsByClassName("intro--main").length){
    let w = window.matchMedia("(max-width: 768px)");
    let videoSonar = document.getElementById("sea");
    let dataVideoSonar = videoSonar.getAttribute('data-src');
    let dataVideoSonarMob = videoSonar.getAttribute('data-mobile-src');
    let playPromiseSonar = videoSonar.play();
    if (w.matches) {
      videoSonar.removeAttribute('controls');
      videoSonar.removeAttribute("autoplay");
      videoSonar.setAttribute("data-autoplay", "false");
      videoSonar.pause();
      videoSonar.src = dataVideoSonarMob;
      videoSonar.load();
      if (playPromiseSonar !== undefined) {
        playPromiseSonar.then(_ => {
          videoSonar.pause();
        })
        .catch(error => {});
      }
    }
    else {
      videoSonar.pause();
      videoSonar.src = dataVideoSonar;
      videoSonar.load();
      if (playPromiseSonar !== undefined) {
        playPromiseSonar.then(_ => {
          videoSonar.pause();
        })
        .catch(error => {});
      }
    }
    // window.addEventListener("resize", function(){
    //   if (w.matches) {
    //     videoSonar.pause();
    //     videoSonar.src = dataVideoSonarMob;
    //     videoSonar.load();
    //     if (playPromiseSonar !== undefined) {
    //       playPromiseSonar.then(_ => {
    //         videoSonar.pause();
    //       })
    //       .catch(error => {});
    //     }
    //   }
    //   else {
    //     videoSonar.pause();
    //     videoSonar.src = dataVideoSonar;
    //     videoSonar.load();
    //     if (playPromiseSonar !== undefined) {
    //       playPromiseSonar.then(_ => {
    //         videoSonar.pause();
    //       })
    //       .catch(error => {});
    //     }
    //   }
    // });
  }
  if (document.getElementsByClassName("footer__bg").length){
    let w = window.matchMedia("(max-width: 768px)");
    let videoStorm = document.getElementById("storm");
    let dataVideoStorm = videoStorm.getAttribute('data-src');
    let dataVideoStormMob = videoStorm.getAttribute('data-mobile-src');
    let playPromiseStorm = videoStorm.play();
    if (w.matches) {
      videoStorm.removeAttribute('controls');
      videoStorm.removeAttribute("autoplay");
      videoStorm.pause();
      videoStorm.src = dataVideoStormMob;
      videoStorm.load();
      if (playPromiseStorm !== undefined) {
        playPromiseStorm.then(_ => {
          videoStorm.pause();
        })
        .catch(error => {});
      }
    }
    else {
      videoStorm.pause();
      videoStorm.src = dataVideoStorm;
      videoStorm.load();
      if (playPromiseStorm !== undefined) {
        playPromiseStorm.then(_ => {
          videoStorm.pause();
        })
        .catch(error => {});
      }
    }
    // window.addEventListener("resize", function(){
    //   if (w.matches) {
    //     videoStorm.pause();
    //     videoStorm.src = dataVideoStormMob;
    //     videoStorm.load();
    //     if (playPromiseStorm !== undefined) {
    //       playPromiseStorm.then(_ => {
    //         videoStorm.pause();
    //       })
    //       .catch(error => {});
    //     }
    //   }
    //   else {
    //     videoStorm.pause();
    //     videoStorm.src = dataVideoStorm;
    //     videoStorm.load();
    //     if (playPromiseStorm !== undefined) {
    //       playPromiseStorm.then(_ => {
    //         videoStorm.pause();
    //       })
    //       .catch(error => {});
    //     }
    //   }
    // });
  }
});