import {navContentPos} from './header-nav';

const navList = document.querySelector('.main-nav__list');
const navMore = document.querySelector('.nav-item-more');
const navMorePattern = document.querySelector('.js-more-pattern');
const moreContentList = document.querySelector('.js-more-list');
const breakpointMd = window.matchMedia('(max-width:1023px)');


const initOverflowCheck = () => {
  window.addEventListener('load', () => {
    initOverflowCheck();
  });

  window.addEventListener('orientationchange', () => {
    initOverflowCheck();

    setTimeout(() => {
      initOverflowCheck();
    }, 150);
  });

  const navItems = document.querySelectorAll('.main-nav__item');

  if (breakpointMd.matches) {
    removeOverflowState(navItems);
    return;
  }

  const navListPos = navList.getBoundingClientRect().right;
  let itemsWidth = 0;
  let filterItems = [];

  navItems.forEach((item) => {
    if (item.getBoundingClientRect().right > navListPos + 1 && item.dataset.more !== 'true') {
      item.classList.add('hidden');
      moveToMore(item);
    }

    if (!item.classList.contains('hidden') && item.dataset.more !== 'true') {
      itemsWidth += item.offsetWidth;
    }

    if (item.classList.contains('hidden') && item.dataset.more !== 'true') {
      filterItems.push(item);
    }
  });

  filterItems.forEach((item) => {
    if (itemsWidth + item.offsetWidth < navList.offsetWidth) {
      removeFromMore(item);
    }
  });

  if (filterItems.length === 0) {
    navMore.classList.add('hidden');
  }
};

const moveToMore = (item) => {
  navMore.classList.remove('hidden');
  let newMoreItem = navMorePattern.cloneNode(true);
  newMoreItem.querySelector('.nav-content__link-text').innerHTML = item.children[0].innerHTML;
  newMoreItem.querySelector('.nav-content__link').href = item.children[0].getAttribute('href');
  moreContentList.innerHTML += newMoreItem.outerHTML;

  setTimeout(() => {
    navContentPos();
  }, 100);
};

const removeFromMore = (item) => {
  let moreContentItems = moreContentList.querySelectorAll('.nav-content__item');
  item.classList.remove('hidden');
  moreContentItems.forEach((moreItem) => {
    if (item.children[0].innerHTML === moreItem.querySelector('.nav-content__link-text').innerHTML) {
      moreItem.remove();
    }
  });
};

const removeOverflowState = (navItems) => {
  navItems.forEach((item) => removeFromMore(item));
  navMore.classList.add('hidden');
};

export {initOverflowCheck};
